import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Box } from '../Box';
import { Props, Playground } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "box"
    }}>{`Box`}</h1>
    <p>{`The Box is the basic building block of most elements in the Hoppin Design System.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Box} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`Box implements the styled-system props for:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#space"
        }}>{`space`}</a>{` (only use full-length props. e.g. `}<inlineCode parentName="li">{`paddingBottom`}</inlineCode>{`, not `}<inlineCode parentName="li">{`pb`}</inlineCode>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#layout"
        }}>{`layout`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#color"
        }}>{`color`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#border"
        }}>{`border`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#flex"
        }}>{`flex`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#flexGrow"
        }}>{`flexGrow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#flexShrink"
        }}>{`flexShrink`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#textAlign"
        }}>{`textAlign`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://styled-system.com/api#shadow"
        }}>{`boxShadow`}</a></li>
    </ul>
    <p>{`The API is intentionally held minimal. By default `}<inlineCode parentName="p">{`<Box/>`}</inlineCode>{` has `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{` set to `}<inlineCode parentName="p">{`1`}</inlineCode>{`, so components built on Box fill all available space and the container components take care of layout.`}</p>
    <p>{`You can override `}<inlineCode parentName="p">{`flex`}</inlineCode>{`, `}<inlineCode parentName="p">{`flexGrow`}</inlineCode>{` and `}<inlineCode parentName="p">{`flexShrink`}</inlineCode>{` props if needed, for full flexbox capabilities (expecially flex containers), use `}<a parentName="p" {...{
        "href": "/components/Flex"
      }}>{`Flex`}</a></p>
    <Playground __position={1} __code={'<Box\n  width={300}\n  padding=\"1rem\"\n  bg=\"neutral.lighter\"\n  border=\"1px solid\"\n  borderColor=\"primary\"\n>\n  Click me\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Box,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Box width={300} padding="1rem" bg="neutral.lighter" border="1px solid" borderColor="primary" mdxType="Box">
    Click me
  </Box>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      